import alterpressApi from './base';

import { TRegisterFormInitialProps } from '../../formik/registration.form';
import { IPasswordResetData, IPasswordChangeData, IUserData } from '../../models/user.model';
import { TLoginFormInitialValues } from '../../formik/login.form';
import { INewsletterFormData } from '../../models/newsletter-form.model';
import { ICourse } from '../../models/course.model';

const userExpandParams = `expand=cart,newsletters,profile`;

export const userApi = alterpressApi
    .enhanceEndpoints({
        addTagTypes: ['User'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            loginUser: builder.mutation<IUserData, TLoginFormInitialValues>({
                query: (data) => ({
                    method: 'POST',
                    url: `account/login?${userExpandParams}`,
                    body: data,
                }),
            }),
            registerUser: builder.mutation<IUserData, TRegisterFormInitialProps>({
                query: (data) => ({
                    method: 'POST',
                    url: `account/register?${userExpandParams}`,
                    body: data,
                }),
            }),
            getUser: builder.query<IUserData, void>({
                query: () => `account?${userExpandParams}`,
            }),
            getUserCourse: builder.query<ICourse, { courseId: string | number | undefined }>({
                query: ({ courseId }) => `courses/${courseId}?expand=media,movies,movies.media`,
            }),
            verifyUser: builder.mutation<void, string>({
                query: (token) => `account/activate/${token}`,
            }),
            logoutUser: builder.mutation<void, void>({
                query: () => ({
                    method: 'POST',
                    url: 'account/logout',
                }),
            }),
            passwordRecovery: builder.mutation<void, void>({
                query: (data) => ({
                    method: 'POST',
                    url: 'account/password-reset-request',
                    body: data,
                }),
            }),
            passwordChange: builder.mutation<void, IPasswordChangeData>({
                query: (data) => ({
                    method: 'POST',
                    url: 'account/password-change',
                    body: data,
                }),
            }),
            passwordReset: builder.mutation<void, IPasswordResetData>({
                query: (data) => ({
                    method: 'POST',
                    url: 'account/password-reset',
                    body: data,
                }),
            }),
            newsletter: builder.mutation<
                void,
                INewsletterFormData & { action: 'subscribe' | 'unsubscribe' }
            >({
                query: ({ action, ...data }) => ({
                    method: 'POST',
                    url: `newsletter/${action}`,
                    body: data,
                }),
                invalidatesTags: ['User'],
            }),
        }),
    });

export const {
    useGetUserQuery,
    useGetUserCourseQuery,
    useLoginUserMutation,
    useRegisterUserMutation,
    useVerifyUserMutation,
    useLogoutUserMutation,
    usePasswordRecoveryMutation,
    usePasswordChangeMutation,
    usePasswordResetMutation,
    useNewsletterMutation,
} = userApi;
