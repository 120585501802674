import alterpressApi from './base';

import { IProfile } from '../../models/profile.model';

export const profileApi = alterpressApi
    .enhanceEndpoints({
        addTagTypes: ['PROFILE'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getProfile: builder.query<IProfile, void>({
                query: () => `account/profile`,
                providesTags: ['PROFILE'],
            }),
            updateProfile: builder.mutation<IProfile, Partial<IProfile>>({
                query: (profile) => ({
                    url: `account/profile`,
                    method: 'PATCH',
                    body: profile,
                }),
                invalidatesTags: ['PROFILE'],
            }),
        }),
    });

export const { useGetProfileQuery, useUpdateProfileMutation } = profileApi;
