import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { userApi } from '../api/user';
import { profileApi } from '../api/profile';
import { RootState } from '../store';
import { IProfile } from '../../models/profile.model';

export interface IProfileState {
    data: IProfile | null;
}

const initialState: IProfileState = {
    data: null,
};

const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            isAnyOf(
                userApi.endpoints.loginUser.matchFulfilled,
                userApi.endpoints.getUser.matchFulfilled,
                userApi.endpoints.registerUser.matchFulfilled
            ),
            (state, action) => {
                const { payload } = action;
                if (payload.profile) {
                    state.data = payload.profile;
                }
            }
        );
        builder.addMatcher(
            isAnyOf(profileApi.endpoints.getProfile.matchFulfilled),
            (state, action) => {
                const { payload } = action;
                state.data = payload;
            }
        );
    },
});

export default profileSlice.reducer;

export const selectProfileState = (state: RootState) => state.profile;
